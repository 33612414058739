<template>
  <div>
    这尼玛是控制台
  </div>
</template>

<script>
export default {
  name: "index",
  data(){
    return{
    }
  },
  mounted() {
    console.log('--999---')
  }
}
</script>

<style scoped>

</style>